// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import BlockHelpers from "../../utilities/src/BlockHelpers";
import { parseFooter } from "../../utilities/src/helpers/utils";
import { Platform } from "../../utilities/src/models/Platform";
import { Navigation } from "../../utilities/src/models/Navigation";
import { AppMixpanel as mixpanel } from "../../../components/src/MixPanel";
import StorageProvider from "../../../framework/src/StorageProvider";
import { eventEmitter } from "../../../framework/src/utils/EventEmitter";
import { DesignDataInt } from "../../header/src/AppheaderController.web";
import { StoreData } from "../../utilities/src/components/Currency.web";

export const configJSON = require("./config");

export interface Footer {
	data: {
		attributes: {
			footer: {
				id: number,
				copy_right_text: string,
				phone_number: string,
				country_code: string,
				social_media: {
					facebook: {
						selected: boolean,
						url: string
					},
					twitter: {
						selected: boolean,
						url: string
					},
					instagram: {
						selected: boolean,
						url: string
					},
					youtube: {
						selected: boolean,
						url: string
					}
				},
				download_apps: {
					android: {
						selected: boolean,
						url: string
					},
					ios: {
						selected: boolean,
						url: string
					}
				}
			}
		}
	}
}

export interface Props {
  navigation: Navigation;
  identifier: string;
}
interface footerAttributes {
  copyright: string,
  facebook_url: string,
  x_url: string,
  youtube_url: string,
  linkedin_url: string,
  instagram_url: string,
  appstore_url: string,
  playstore_url: string,
  design_id: number,
  is_social_media: boolean,
  is_app_link: boolean,
  is_contact: boolean,
  phone_number: string,
  country_code: string,
  address: string
}

export interface footersData {
  id: string;
  type: string;
  attributes?: footerAttributes;
}

interface S {
  phoneNumber: string;
  footerEmail:string;
  whatsAppNumber:string;
  whatsAppMessage:string;
  isWhatsappIntegration:boolean;
  socialMedias: Record<string, Platform>;
  applications: Record<string, Platform>;
  copyright: string;
  staticPages: StaticPage[];
  brandFooterFacebookUrl:string;
  brandFooterInstagramUrl:string;
  brandFooterXurl:string;
  brandFooterYoutubeurl:string;
  brandFooteAppStoreUrl:string;
  brandFootePlayStoreUrl:string;
  copyRight:string;
  brandFootertogle:boolean;
  brandFooterContact:boolean;
  brandFooterApplink:boolean;
  isLoading:boolean
  loading:boolean;
  buildCardId: string;
  footerData:footersData;
}

interface SS {
  identifier: string;
}

export type StaticPage = {
  id: string;
  type: string;
  attributes: {
    id: number;
    title: string;
    description: {
      content: string;
    };
    active: boolean;
    position: number;
    page_type: {
      id: number;
      name: string;
    };
    page_slug: string;
    created_at: string;
    updated_at: string;
  };
};

export default class AppfooterController extends BlockComponent<Props, S, SS> {
  getStaticPagesMessageId: string;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      phoneNumber: "",
      footerEmail:"",
      whatsAppNumber:"",
      whatsAppMessage:"",
      isWhatsappIntegration:false,
      socialMedias: {},
      applications: {},
      copyright: "",
      staticPages: [],
      brandFooterFacebookUrl:"",
      brandFooterInstagramUrl:"",
      brandFooterXurl:"",
      brandFooterYoutubeurl:"",
      brandFooteAppStoreUrl:"",
      brandFootePlayStoreUrl:"",
      copyRight:"",
      brandFootertogle:false,
      brandFooterContact:false,
      brandFooterApplink:false,
      isLoading:false,
      loading:false,
      buildCardId: "",
      footerData: {} as footersData
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getStaticPages();

    const buildCardId = await StorageProvider.get("buildcardID");
    const storeDetails = JSON.parse(await StorageProvider.get("storeDetails"));

    if (buildCardId){
      this.setState({ buildCardId: JSON.parse(buildCardId) });
    }

    eventEmitter.subscribe("updateDesignDetails", async ({ designData }: DesignDataInt) => {
      this.setState({
        footerData: designData.footer.data,
        brandFooterFacebookUrl: designData.footer.data.attributes.facebook_url,
        brandFooterInstagramUrl: designData.footer.data.attributes.instagram_url,
        brandFooterXurl: designData.footer.data.attributes.x_url,
        brandFooterYoutubeurl: designData.footer.data.attributes.youtube_url,
        brandFootertogle: designData.footer.data.attributes.is_social_media,
        brandFooterContact: designData.footer.data.attributes.is_contact,
        brandFooterApplink: designData.footer.data.attributes.is_app_link,
        brandFooteAppStoreUrl: designData.footer.data.attributes.appstore_url,
        brandFootePlayStoreUrl: designData.footer.data.attributes.playstore_url,
        copyRight: designData.footer.data.attributes.copyright,
        phoneNumber: storeDetails?.phone_number || ""
      })
    });

    eventEmitter.subscribe("updateStoreData", async ({storeData}: StoreData) => {
      this.setState({
        footerEmail: storeData.customer_support_email,
        isWhatsappIntegration: storeData.is_whatsapp_integration,
        whatsAppNumber: storeData.whatsapp_number,
        whatsAppMessage: storeData.whatsapp_message
      })
    });
  }

  receive = async (from: string, message: Message) => {
    runEngine.debugLog("Message Recived", message);

    if(this.getStaticPagesMessageId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))){
        const responseJSON = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if(responseJSON?.data){
          this.setState({
            staticPages: responseJSON.data.filter((staticPage: StaticPage) => staticPage.attributes.active,
           ), 
          })
        }else{
          const errorReponse = message.getData(
            getName(MessageEnum.RestAPIResponceErrorMessage)
          );

          mixpanel.track(`${this.state.buildCardId}_webcustomer_error_occurred_in_static_page`,
          {
              error: errorReponse, 
              buildCardID: this.state.buildCardId
          });
  
          this.parseApiCatchErrorResponse(errorReponse);
        }
      }
  };

  handleGetBrandsResponse = (
    responseJson: Footer
  ) => {
    const response = responseJson;
    
    const {
      socialMedias,
      applications,
      copyright,
    } = parseFooter(response);

    this.setState({
      socialMedias,
      applications,
      copyright,
    });
  };

  getStaticPages = () => {
    const header = {
      "Content-Type": configJSON.contentTypeApplicationJson
    };

    this.getStaticPagesMessageId = BlockHelpers.callApi({
      method: configJSON.getMethod,
      endPoint: configJSON.getStaticPagesEndPoint,
      header
    });
  }
}
// Customizable Area End
